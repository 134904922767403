import { AuthService } from "services";

const { API } = require("aws-amplify");

export const canAccountPurchaseL45 = async () => {
  const sess = await AuthService.checkLoginState();

  // if (!!sess) {
  //   const myInit = {};

  //   const result = await API.get("CertL45", "/can-purchase", myInit);
  //   // console.log(result);
  //   return result;
  // }
  return false;
};
