import "./routes.scss";

import { useEffect, useState } from "react";

import {
  canAccountPurchaseL45,
  getActiveApp,
  getIssuedBadge,
  getOrganisation,
  getSignedAttestationDownloadUrl,
  getSpecificLevelCertificateDownloadUrl,
  getUser,
  onUpdateActiveApp,
} from "api";
import { getCertificationInfo } from "api/functions/certification/info";
import { Hub } from "aws-amplify";
import { decode as base64_decode } from "base-64";
import {
  LayoutWrapper,
  MetaDescription,
  RequireAuth,
  RequireNoAuth,
  ScrollToTop,
} from "components";
import { AuthLayout } from "layouts";
import {
  useAppSessionState,
  useAppState,
  useDownloadUrlState,
} from "middleware";
import {
  AccountCertification,
  AssessmentResults,
  AssessmentTool,
  Certificate,
  CertificateOptions,
  CertificationManager,
  Cookie,
  Dashboard,
  IssuedCertificatePage,
  IssuedSamplePage,
  Login,
  Maintenance,
  MfaConfirm,
  NoMobile,
  PageNotFound,
  PrivacyPolicy,
  Register,
  RegisterConfirm,
  ResetPassword,
  Terms,
  TestStripe,
  Upgrades,
  Video,
} from "pages";
import queryString from "query-string";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthService, clearUrlParamsState, openZEWidget } from "services";
import { ROUTES } from "variables";

export function Router() {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  const { appSession, setSession } = useAppSessionState();
  const { downloadUrl, setDownloadUrl } = useDownloadUrlState();
  const { app, setApp } = useAppState();

  const isMobile = width <= 0;

  const setupApp = async () => {
    const a = await getActiveApp();
    const enableDirectPurchaseL45 = await canAccountPurchaseL45();
    // console.log(a);
    setApp({
      ...a,
      enableDirectPurchaseL45,
    });
  };

  const checkDownloadLinks = async () => {
    const userData = await getUser(appSession?.session?.id);

    const certificationInfo = await getCertificationInfo();

    const curProgress = certificationInfo?.customer?.certification?.progress;

    if (!!userData) {
      let urls = downloadUrl;
      if (curProgress?.step >= 5 && downloadUrl.signedDeedLink === "/") {
        await getSignedAttestationDownloadUrl()
          .then((result) => {
            urls = {
              ...urls,
              signedDeedLink: result,
            };
          })
          .catch((err) => {});
      }
      if (
        curProgress?.status === "COMPLETED" &&
        downloadUrl.certificateLink === "/"
      ) {
        await getSpecificLevelCertificateDownloadUrl(
          certificationInfo?.customer?.certification?.level,
        )
          .then((result) => {
            urls = {
              ...urls,
              certificateLink: result,
            };
          })
          .catch((err) => {});
      }
      if (curProgress?.status === "COMPLETED" && downloadUrl.badges === "/") {
        await getIssuedBadge({
          level: certificationInfo?.customer?.certification?.level,
        })
          .then((result) => {
            urls = {
              ...urls,
              badges: result,
            };
          })
          .catch((err) => {});
      }
      setDownloadUrl(urls);
    }
  };

  const onSignin = () => {
    AuthService.getCurrentAuthUserInfo().then((info) => {
      setSession(info);
      getUser(info?.id).then((userData) => updateUser(userData));
    });
  };

  const updateUser = async (userData) => {
    let organisation = await getOrganisation(userData?.ABN);

    setUser({ ...userData, organisation });
  };

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    return Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
        case "signOut":
        case "signIn_failure":
        case "cognitoHostedUI_failure":
        default:
      }
    });
  }, []);

  useEffect(() => {
    setupApp();

    return onUpdateActiveApp({
      callbackFn: setApp,
    });
  }, []);

  useEffect(() => {
    AuthService.getCurrentAuthUserInfo()
      .then((info) => {
        setSession(info);
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUser(appSession?.session?.id).then((userData) => {
      updateUser(userData);
      // console.log(userData);
    });
    checkDownloadLinks();
  }, [appSession]);

  useEffect(() => {
    const interval = setInterval(() => checkDownloadLinks(), 3600000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!!location.search) {
      const params = queryString.parse(location.search);
      if (params["zewidget"] === "1") {
        setTimeout(() => {
          openZEWidget();
        }, 1000);
      }
      try {
        if (!!params?.c) {
          const cParams = JSON.parse(base64_decode(params["c"]));
          // check if any partner invites to accept
          if (!!cParams["partnerInvite"]) {
            sessionStorage.setItem(
              "ACCEPT_PARTNER_INVITE_BY_LOGIN",
              cParams["partnerInvite"],
            );
          }
          const rememberMe = cParams?.rememberMe || false;
          // configureAuthStorageByRememberOption(rememberMe);
          if (cParams["login"] === "Facebook") {
            AuthService.signinWithFacebook();
          } else if (
            cParams["login"] === "Google" &&
            cParams["partner"] === true
          ) {
            AuthService.partnerSigninWithGoogle();
          } else if (cParams["login"] === "Google") {
            AuthService.signinWithGoogle();
          } else if (
            cParams["login"] === "email" &&
            cParams["u"] &&
            cParams["p"]
          ) {
            const { u, p, partner } = cParams;

            let signinParams = {
              email: u,
              password: p,
              navigateCallback: () => (window.location.href = ROUTES.ROOT),
              onSignin,
              onSigninCallback: () =>
                navigate(ROUTES.REGISTER_CONFIRM, {
                  state: {
                    registerEmail: u,
                  },
                }),
              confirmCallback: () => {},
              mfaCallback: () => {
                navigate(ROUTES.MFA_VERIFY, {
                  state: { email: u, password: p },
                });
              },
            };

            if (partner) {
              AuthService.partnerSignIn(signinParams);
            } else {
              AuthService.signIn(signinParams);
            }
          }
        }
      } catch (e) {
        console.log(`LOGIN ERR ${JSON.stringify(e)}`);
      }
      clearUrlParamsState();
    }
  }, []);

  return (
    <>
      <div className="routes-container">
        <ScrollToTop>
          <Routes>
            {app?.current?.mode === "MAINTENANCE" || isMaintenanceMode ? (
              <>
                <Route path={ROUTES.MAINTENANCE} element={<Maintenance />} />

                <Route
                  path={ROUTES.PAGE_NOT_FOUND}
                  element={<Navigate to={ROUTES.MAINTENANCE} />}
                />
              </>
            ) : isMobile ? (
              <>
                <Route
                  path={ROUTES.PAGE_NOT_FOUND}
                  element={
                    <LayoutWrapper>
                      <NoMobile />
                    </LayoutWrapper>
                  }
                />

                <Route
                  exact
                  path={ROUTES.CERTIFICATE_VIEW}
                  element={
                    <LayoutWrapper>
                      <Certificate />
                    </LayoutWrapper>
                  }
                />

                <Route
                  exact
                  path={ROUTES.ISSUED}
                  element={
                    <LayoutWrapper>
                      <IssuedCertificatePage />
                    </LayoutWrapper>
                  }
                />

                <Route
                  path={ROUTES.ISSUED_SAMPLE}
                  element={
                    <LayoutWrapper>
                      <IssuedSamplePage />
                    </LayoutWrapper>
                  }
                />

                <Route
                  path={ROUTES[404]}
                  element={
                    <LayoutWrapper>
                      <PageNotFound />
                    </LayoutWrapper>
                  }
                />

                <Route
                  path={ROUTES.PAGE_NOT_FOUND}
                  element={
                    <LayoutWrapper>
                      <PageNotFound />
                    </LayoutWrapper>
                  }
                />
              </>
            ) : (
              <>
                <Route
                  exact
                  path={ROUTES.ROOT}
                  element={
                    <RequireAuth navigate={navigate}>
                      <LayoutWrapper>
                        <Dashboard user={user} />
                      </LayoutWrapper>
                    </RequireAuth>
                  }
                />

                <Route
                  exact
                  path={ROUTES.CERTIFICATE_VIEW}
                  element={
                    <LayoutWrapper>
                      <Certificate />
                    </LayoutWrapper>
                  }
                />

                <Route exact path="/video" element={<Video />} />

                <Route
                  exact
                  path={ROUTES.PRIVACY_POLICY}
                  element={
                    <LayoutWrapper>
                      <PrivacyPolicy />
                    </LayoutWrapper>
                  }
                />

                <Route
                  exact
                  path={ROUTES.TERMS}
                  element={
                    <LayoutWrapper>
                      <Terms />
                    </LayoutWrapper>
                  }
                />

                <Route
                  exact
                  path={ROUTES.COOKIE}
                  element={
                    <LayoutWrapper>
                      <Cookie />
                    </LayoutWrapper>
                  }
                />

                <Route
                  exact
                  path={ROUTES.LOGIN}
                  element={
                    <RequireNoAuth navigate={navigate}>
                      <AuthLayout
                        metaDescription={
                          <MetaDescription
                            pageTitle="Login"
                            pageDescription="Login to your CyberCert account"
                          />
                        }
                      >
                        <Login onSignin={onSignin} />
                      </AuthLayout>
                    </RequireNoAuth>
                  }
                />

                <Route
                  exact
                  path={ROUTES.MFA_VERIFY}
                  element={
                    <RequireNoAuth navigate={navigate}>
                      <AuthLayout
                        metaDescription={
                          <MetaDescription
                            pageTitle="Login"
                            pageDescription="Login to your CyberCert account"
                          />
                        }
                      >
                        <MfaConfirm onSignin={onSignin} />
                      </AuthLayout>
                    </RequireNoAuth>
                  }
                />

                <Route
                  exact
                  path={ROUTES.REGISTER}
                  element={
                    <RequireNoAuth navigate={navigate}>
                      <AuthLayout
                        metaDescription={
                          <MetaDescription
                            pageTitle="Register"
                            pageDescription="Complete your registration with CyberCert"
                          />
                        }
                      >
                        <Register />
                      </AuthLayout>
                    </RequireNoAuth>
                  }
                />

                <Route
                  exact
                  path={ROUTES.REGISTER_CONFIRM}
                  element={
                    <RequireNoAuth navigate={navigate}>
                      <AuthLayout
                        metaDescription={
                          <MetaDescription
                            pageTitle="Confirm your registration"
                            pageDescription="Please submit the code we have sent to your email to confirm your registration with CyberCert."
                          />
                        }
                      >
                        <RegisterConfirm
                          onConfirm={() => navigate(ROUTES.ROOT)}
                        />
                      </AuthLayout>
                    </RequireNoAuth>
                  }
                />

                <Route
                  exact
                  path={ROUTES.DASHBOARD}
                  element={
                    <RequireAuth navigate={navigate}>
                      <LayoutWrapper>
                        <Dashboard user={user} />
                      </LayoutWrapper>
                    </RequireAuth>
                  }
                />

                <Route
                  exact
                  path={ROUTES.CERTIFICATION_MANAGER}
                  element={
                    <RequireAuth navigate={navigate}>
                      <LayoutWrapper>
                        <CertificationManager user={user} />
                      </LayoutWrapper>
                    </RequireAuth>
                  }
                />

                <Route
                  exact
                  path={ROUTES.ACCOUNT}
                  element={
                    <RequireAuth navigate={navigate}>
                      <LayoutWrapper>
                        <AccountCertification user={user} />
                      </LayoutWrapper>
                    </RequireAuth>
                  }
                />

                <Route
                  exact
                  path={ROUTES.RESET_PASSWORD}
                  element={
                    <RequireNoAuth navigate={navigate}>
                      <AuthLayout
                        metaDescription={
                          <MetaDescription
                            pageTitle="Reset your password"
                            pageDescription="Reset your password"
                          />
                        }
                      >
                        <ResetPassword />
                      </AuthLayout>
                    </RequireNoAuth>
                  }
                />

                <Route
                  exact
                  path={ROUTES.ASSESSMENT_TOOL}
                  element={
                    // <RequireNoAuth navigate={navigate}>
                    <LayoutWrapper>
                      <AssessmentTool />
                    </LayoutWrapper>
                    // </RequireNoAuth>
                  }
                />

                <Route
                  exact
                  path={ROUTES.ASSESSMENT_RESULTS}
                  element={
                    // <RequireNoAuth navigate={navigate}>
                    <LayoutWrapper>
                      <AssessmentResults user={user} />
                    </LayoutWrapper>
                    // </RequireNoAuth>
                  }
                />

                <Route
                  exact
                  path={ROUTES.UPGRADES}
                  element={
                    <RequireAuth navigate={navigate}>
                      <LayoutWrapper>
                        <Upgrades />
                      </LayoutWrapper>
                    </RequireAuth>
                  }
                />

                <Route
                  exact
                  path={ROUTES.OPTIONS}
                  element={
                    <RequireAuth navigate={navigate}>
                      <LayoutWrapper>
                        <CertificateOptions user={user} />
                      </LayoutWrapper>
                    </RequireAuth>
                  }
                />

                <Route
                  path={"/test/stripe"}
                  element={
                    <LayoutWrapper>
                      <TestStripe />
                    </LayoutWrapper>
                  }
                />

                <Route
                  exact
                  path={ROUTES.ISSUED}
                  element={
                    <LayoutWrapper>
                      <IssuedCertificatePage />
                    </LayoutWrapper>
                  }
                />

                <Route
                  path={ROUTES.ISSUED_SAMPLE}
                  element={
                    <LayoutWrapper watermark>
                      <IssuedSamplePage />
                    </LayoutWrapper>
                  }
                />

                <Route
                  path={ROUTES.MAINTENANCE}
                  element={<Navigate to={ROUTES.ROOT} />}
                />

                <Route
                  path={ROUTES[404]}
                  element={
                    <LayoutWrapper>
                      <PageNotFound />
                    </LayoutWrapper>
                  }
                />

                <Route
                  path={ROUTES.PAGE_NOT_FOUND}
                  element={
                    <LayoutWrapper>
                      <PageNotFound />
                    </LayoutWrapper>
                  }
                />
              </>
            )}
          </Routes>
        </ScrollToTop>
      </div>
    </>
  );
}
