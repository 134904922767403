/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getApp = /* GraphQL */ `
  query GetApp($id: ID!) {
    getApp(id: $id) {
      id
      mode
      paymentMode
      enableMonthlyReport
      maintenanceSchedule
      maintenanceScheduleDisplayTimeFormat
      adminNotificationCertCompletion
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApps = /* GraphQL */ `
  query ListApps(
    $filter: ModelAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mode
        paymentMode
        enableMonthlyReport
        maintenanceSchedule
        maintenanceScheduleDisplayTimeFormat
        adminNotificationCertCompletion
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($ABN: String!) {
    getOrganisation(ABN: $ABN) {
      ABN
      orgId
      ACN
      self
      createdAt
      country
      name
      businessName
      displayName
      industry
      type
      email
      phone
      addressStreet
      addressSuburb
      addressState
      addressPostcode
      ANZSIC
      headOfficeStreetName
      headOfficeSuburb
      headOfficeState
      headOfficePostcode
      headOfficeCountry
      postalStreetName
      postalSuburb
      postalState
      postalPostcode
      postalCountry
      websiteDomain
      emailDomain
      enableEmailDomainMonitoring
      website
      deleted
      certificationLevel
      confirmed
      addedBy
      addedTo
      addedAs
      addedInProgress
      owners
      updatedAt
      __typename
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $ABN: String
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganisations(
      ABN: $ABN
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ABN
        orgId
        ACN
        self
        createdAt
        country
        name
        businessName
        displayName
        industry
        type
        email
        phone
        addressStreet
        addressSuburb
        addressState
        addressPostcode
        ANZSIC
        headOfficeStreetName
        headOfficeSuburb
        headOfficeState
        headOfficePostcode
        headOfficeCountry
        postalStreetName
        postalSuburb
        postalState
        postalPostcode
        postalCountry
        websiteDomain
        emailDomain
        enableEmailDomainMonitoring
        website
        deleted
        certificationLevel
        confirmed
        addedBy
        addedTo
        addedAs
        addedInProgress
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const organisationsByDate = /* GraphQL */ `
  query OrganisationsByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organisationsByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ABN
        orgId
        ACN
        self
        createdAt
        country
        name
        businessName
        displayName
        industry
        type
        email
        phone
        addressStreet
        addressSuburb
        addressState
        addressPostcode
        ANZSIC
        headOfficeStreetName
        headOfficeSuburb
        headOfficeState
        headOfficePostcode
        headOfficeCountry
        postalStreetName
        postalSuburb
        postalState
        postalPostcode
        postalCountry
        websiteDomain
        emailDomain
        enableEmailDomainMonitoring
        website
        deleted
        certificationLevel
        confirmed
        addedBy
        addedTo
        addedAs
        addedInProgress
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($ABN: String!) {
    getPartner(ABN: $ABN) {
      ABN
      orgId
      name
      location
      entityType
      self
      createdAt
      commencementDate
      type
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      supplyChainCustomers
      membershipCustomers
      MSPCustomers
      referrerCustomers
      franchiseCustomers
      consultantCustomers
      vendorCustomers
      supplyChainReferrals
      membershipReferrals
      MSPReferrals
      referrerReferrals
      franchiseReferrals
      consultantReferrals
      vendorReferrals
      commission
      recruitedBy
      firstName
      lastName
      email
      phone
      role
      isActive
      isSetup
      isOfferSetup
      setupAt
      discount
      minimumVolume
      subscriptionDiscount
      onboardStep
      onboardCompletedAt
      claimURL
      codeExpiry
      volumeDiscount
      website
      logo
      favicon
      uploadedCount
      pendingABNs
      pendingPartnerOrgs
      addOrgStep
      dashboardType
      referredBy
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $ABN: String
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPartners(
      ABN: $ABN
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ABN
        orgId
        name
        location
        entityType
        self
        createdAt
        commencementDate
        type
        isTypeCAP
        isTypeReferrer
        isTypeMSP
        isTypeConsultant
        isTypeSupplyChain
        isTypeMembership
        isTypeFranchise
        isTypeVendor
        supplyChainCustomers
        membershipCustomers
        MSPCustomers
        referrerCustomers
        franchiseCustomers
        consultantCustomers
        vendorCustomers
        supplyChainReferrals
        membershipReferrals
        MSPReferrals
        referrerReferrals
        franchiseReferrals
        consultantReferrals
        vendorReferrals
        commission
        recruitedBy
        firstName
        lastName
        email
        phone
        role
        isActive
        isSetup
        isOfferSetup
        setupAt
        discount
        minimumVolume
        subscriptionDiscount
        onboardStep
        onboardCompletedAt
        claimURL
        codeExpiry
        volumeDiscount
        website
        logo
        favicon
        uploadedCount
        pendingABNs
        pendingPartnerOrgs
        addOrgStep
        dashboardType
        referredBy
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const partnersByDate = /* GraphQL */ `
  query PartnersByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnersByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ABN
        orgId
        name
        location
        entityType
        self
        createdAt
        commencementDate
        type
        isTypeCAP
        isTypeReferrer
        isTypeMSP
        isTypeConsultant
        isTypeSupplyChain
        isTypeMembership
        isTypeFranchise
        isTypeVendor
        supplyChainCustomers
        membershipCustomers
        MSPCustomers
        referrerCustomers
        franchiseCustomers
        consultantCustomers
        vendorCustomers
        supplyChainReferrals
        membershipReferrals
        MSPReferrals
        referrerReferrals
        franchiseReferrals
        consultantReferrals
        vendorReferrals
        commission
        recruitedBy
        firstName
        lastName
        email
        phone
        role
        isActive
        isSetup
        isOfferSetup
        setupAt
        discount
        minimumVolume
        subscriptionDiscount
        onboardStep
        onboardCompletedAt
        claimURL
        codeExpiry
        volumeDiscount
        website
        logo
        favicon
        uploadedCount
        pendingABNs
        pendingPartnerOrgs
        addOrgStep
        dashboardType
        referredBy
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($ABN: String!) {
    getCustomer(ABN: $ABN) {
      ABN
      orgId
      self
      createdAt
      partners
      users
      purchasedLevel
      certifications
      certificationId
      certification {
        id
        self
        createdAt
        certID
        ABN
        orgId
        level
        progressId
        progress {
          id
          level
          self
          createdAt
          certID
          ABN
          orgId
          status
          role
          title
          firstName
          lastName
          email
          mobile
          requirements {
            items {
              id
              progressId
              requirementId
              ABN
              orgId
              self
              createdAt
              attestationStatus
              attestationNote
              attestedBy
              notApplicableDetails
              detail {
                requirementId
                baseId
                self
                createdAt
                title
                description
                notApplicable
                deleted
                updatedAt
                __typename
              }
              assignedToITSpecialist
              deleted
              owners
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          step
          envelopeId
          attestationResendTime
          attestationSigned
          attestationReviewed
          dateLodged
          confetti
          expiredDate
          issuedDate
          completedDate
          workbookDownloadedDate
          excelDownloadedDate
          credlyAcceptedDate
          deleted
          evidenceWorkbookUrl
          evidenceWworksheetUrl
          evidenceNote
          owners
          updatedAt
          __typename
        }
        totalStep
        duration
        purchasedDate
        source
        standard
        deleted
        owners
        updatedAt
        __typename
      }
      deleted
      upgraded
      guidePurchased
      guidePurchasedDate
      isActive
      isSetup
      onboardCompletedAt
      onboardStep
      onboardBy
      internalSpecialist
      externalSpecialist
      updatedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $ABN: String
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomers(
      ABN: $ABN
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ABN
        orgId
        self
        createdAt
        partners
        users
        purchasedLevel
        certifications
        certificationId
        certification {
          id
          self
          createdAt
          certID
          ABN
          orgId
          level
          progressId
          progress {
            id
            level
            self
            createdAt
            certID
            ABN
            orgId
            status
            role
            title
            firstName
            lastName
            email
            mobile
            requirements {
              items {
                id
                progressId
                requirementId
                ABN
                orgId
                self
                createdAt
                attestationStatus
                attestationNote
                attestedBy
                notApplicableDetails
                assignedToITSpecialist
                deleted
                owners
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            step
            envelopeId
            attestationResendTime
            attestationSigned
            attestationReviewed
            dateLodged
            confetti
            expiredDate
            issuedDate
            completedDate
            workbookDownloadedDate
            excelDownloadedDate
            credlyAcceptedDate
            deleted
            evidenceWorkbookUrl
            evidenceWworksheetUrl
            evidenceNote
            owners
            updatedAt
            __typename
          }
          totalStep
          duration
          purchasedDate
          source
          standard
          deleted
          owners
          updatedAt
          __typename
        }
        deleted
        upgraded
        guidePurchased
        guidePurchasedDate
        isActive
        isSetup
        onboardCompletedAt
        onboardStep
        onboardBy
        internalSpecialist
        externalSpecialist
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customersByDate = /* GraphQL */ `
  query CustomersByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ABN
        orgId
        self
        createdAt
        partners
        users
        purchasedLevel
        certifications
        certificationId
        certification {
          id
          self
          createdAt
          certID
          ABN
          orgId
          level
          progressId
          progress {
            id
            level
            self
            createdAt
            certID
            ABN
            orgId
            status
            role
            title
            firstName
            lastName
            email
            mobile
            requirements {
              items {
                id
                progressId
                requirementId
                ABN
                orgId
                self
                createdAt
                attestationStatus
                attestationNote
                attestedBy
                notApplicableDetails
                assignedToITSpecialist
                deleted
                owners
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            step
            envelopeId
            attestationResendTime
            attestationSigned
            attestationReviewed
            dateLodged
            confetti
            expiredDate
            issuedDate
            completedDate
            workbookDownloadedDate
            excelDownloadedDate
            credlyAcceptedDate
            deleted
            evidenceWorkbookUrl
            evidenceWworksheetUrl
            evidenceNote
            owners
            updatedAt
            __typename
          }
          totalStep
          duration
          purchasedDate
          source
          standard
          deleted
          owners
          updatedAt
          __typename
        }
        deleted
        upgraded
        guidePurchased
        guidePurchasedDate
        isActive
        isSetup
        onboardCompletedAt
        onboardStep
        onboardBy
        internalSpecialist
        externalSpecialist
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdmin = /* GraphQL */ `
  query GetAdmin($id: ID!) {
    getAdmin(id: $id) {
      id
      self
      createdAt
      firstName
      lastName
      email
      phone
      role
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        self
        createdAt
        firstName
        lastName
        email
        phone
        role
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const adminsByDate = /* GraphQL */ `
  query AdminsByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminsByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        self
        createdAt
        firstName
        lastName
        email
        phone
        role
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      ABN
      orgId
      organisations
      self
      createdAt
      customer
      firstName
      lastName
      email
      phone
      phoneVerified
      role
      type
      organisation
      owner
      preferredMFA
      mfaUpdatedAt
      loginAt
      loginLocation
      loginDevice
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ABN
        orgId
        organisations
        self
        createdAt
        customer
        firstName
        lastName
        email
        phone
        phoneVerified
        role
        type
        organisation
        owner
        preferredMFA
        mfaUpdatedAt
        loginAt
        loginLocation
        loginDevice
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByDate = /* GraphQL */ `
  query UsersByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ABN
        orgId
        organisations
        self
        createdAt
        customer
        firstName
        lastName
        email
        phone
        phoneVerified
        role
        type
        organisation
        owner
        preferredMFA
        mfaUpdatedAt
        loginAt
        loginLocation
        loginDevice
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCertification = /* GraphQL */ `
  query GetCertification($id: ID!) {
    getCertification(id: $id) {
      id
      self
      createdAt
      certID
      ABN
      orgId
      level
      progressId
      progress {
        id
        level
        self
        createdAt
        certID
        ABN
        orgId
        status
        role
        title
        firstName
        lastName
        email
        mobile
        requirements {
          items {
            id
            progressId
            requirementId
            ABN
            orgId
            self
            createdAt
            attestationStatus
            attestationNote
            attestedBy
            notApplicableDetails
            detail {
              requirementId
              baseId
              self
              createdAt
              title
              description
              notApplicable
              deleted
              updatedAt
              __typename
            }
            assignedToITSpecialist
            deleted
            owners
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        step
        envelopeId
        attestationResendTime
        attestationSigned
        attestationReviewed
        dateLodged
        confetti
        expiredDate
        issuedDate
        completedDate
        workbookDownloadedDate
        excelDownloadedDate
        credlyAcceptedDate
        deleted
        evidenceWorkbookUrl
        evidenceWworksheetUrl
        evidenceNote
        owners
        updatedAt
        __typename
      }
      totalStep
      duration
      purchasedDate
      source
      standard
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const listCertifications = /* GraphQL */ `
  query ListCertifications(
    $filter: ModelCertificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        self
        createdAt
        certID
        ABN
        orgId
        level
        progressId
        progress {
          id
          level
          self
          createdAt
          certID
          ABN
          orgId
          status
          role
          title
          firstName
          lastName
          email
          mobile
          requirements {
            items {
              id
              progressId
              requirementId
              ABN
              orgId
              self
              createdAt
              attestationStatus
              attestationNote
              attestedBy
              notApplicableDetails
              detail {
                requirementId
                baseId
                self
                createdAt
                title
                description
                notApplicable
                deleted
                updatedAt
                __typename
              }
              assignedToITSpecialist
              deleted
              owners
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          step
          envelopeId
          attestationResendTime
          attestationSigned
          attestationReviewed
          dateLodged
          confetti
          expiredDate
          issuedDate
          completedDate
          workbookDownloadedDate
          excelDownloadedDate
          credlyAcceptedDate
          deleted
          evidenceWorkbookUrl
          evidenceWworksheetUrl
          evidenceNote
          owners
          updatedAt
          __typename
        }
        totalStep
        duration
        purchasedDate
        source
        standard
        deleted
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const certificationsByDate = /* GraphQL */ `
  query CertificationsByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCertificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    certificationsByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        self
        createdAt
        certID
        ABN
        orgId
        level
        progressId
        progress {
          id
          level
          self
          createdAt
          certID
          ABN
          orgId
          status
          role
          title
          firstName
          lastName
          email
          mobile
          requirements {
            items {
              id
              progressId
              requirementId
              ABN
              orgId
              self
              createdAt
              attestationStatus
              attestationNote
              attestedBy
              notApplicableDetails
              detail {
                requirementId
                baseId
                self
                createdAt
                title
                description
                notApplicable
                deleted
                updatedAt
                __typename
              }
              assignedToITSpecialist
              deleted
              owners
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          step
          envelopeId
          attestationResendTime
          attestationSigned
          attestationReviewed
          dateLodged
          confetti
          expiredDate
          issuedDate
          completedDate
          workbookDownloadedDate
          excelDownloadedDate
          credlyAcceptedDate
          deleted
          evidenceWorkbookUrl
          evidenceWworksheetUrl
          evidenceNote
          owners
          updatedAt
          __typename
        }
        totalStep
        duration
        purchasedDate
        source
        standard
        deleted
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCertificationProgress = /* GraphQL */ `
  query GetCertificationProgress($id: ID!) {
    getCertificationProgress(id: $id) {
      id
      level
      self
      createdAt
      certID
      ABN
      orgId
      status
      role
      title
      firstName
      lastName
      email
      mobile
      requirements {
        items {
          id
          progressId
          requirementId
          ABN
          orgId
          self
          createdAt
          attestationStatus
          attestationNote
          attestedBy
          notApplicableDetails
          detail {
            requirementId
            baseId
            self
            createdAt
            title
            description
            notApplicable
            deleted
            updatedAt
            __typename
          }
          assignedToITSpecialist
          deleted
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      step
      envelopeId
      attestationResendTime
      attestationSigned
      attestationReviewed
      dateLodged
      confetti
      expiredDate
      issuedDate
      completedDate
      workbookDownloadedDate
      excelDownloadedDate
      credlyAcceptedDate
      deleted
      evidenceWorkbookUrl
      evidenceWworksheetUrl
      evidenceNote
      owners
      updatedAt
      __typename
    }
  }
`;
export const listCertificationProgresses = /* GraphQL */ `
  query ListCertificationProgresses(
    $filter: ModelCertificationProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertificationProgresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        level
        self
        createdAt
        certID
        ABN
        orgId
        status
        role
        title
        firstName
        lastName
        email
        mobile
        requirements {
          items {
            id
            progressId
            requirementId
            ABN
            orgId
            self
            createdAt
            attestationStatus
            attestationNote
            attestedBy
            notApplicableDetails
            detail {
              requirementId
              baseId
              self
              createdAt
              title
              description
              notApplicable
              deleted
              updatedAt
              __typename
            }
            assignedToITSpecialist
            deleted
            owners
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        step
        envelopeId
        attestationResendTime
        attestationSigned
        attestationReviewed
        dateLodged
        confetti
        expiredDate
        issuedDate
        completedDate
        workbookDownloadedDate
        excelDownloadedDate
        credlyAcceptedDate
        deleted
        evidenceWorkbookUrl
        evidenceWworksheetUrl
        evidenceNote
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const certificationProgressesByDate = /* GraphQL */ `
  query CertificationProgressesByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCertificationProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    certificationProgressesByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        level
        self
        createdAt
        certID
        ABN
        orgId
        status
        role
        title
        firstName
        lastName
        email
        mobile
        requirements {
          items {
            id
            progressId
            requirementId
            ABN
            orgId
            self
            createdAt
            attestationStatus
            attestationNote
            attestedBy
            notApplicableDetails
            detail {
              requirementId
              baseId
              self
              createdAt
              title
              description
              notApplicable
              deleted
              updatedAt
              __typename
            }
            assignedToITSpecialist
            deleted
            owners
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        step
        envelopeId
        attestationResendTime
        attestationSigned
        attestationReviewed
        dateLodged
        confetti
        expiredDate
        issuedDate
        completedDate
        workbookDownloadedDate
        excelDownloadedDate
        credlyAcceptedDate
        deleted
        evidenceWorkbookUrl
        evidenceWworksheetUrl
        evidenceNote
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRequirement = /* GraphQL */ `
  query GetRequirement($id: ID!) {
    getRequirement(id: $id) {
      id
      progressId
      requirementId
      ABN
      orgId
      self
      createdAt
      attestationStatus
      attestationNote
      attestedBy
      notApplicableDetails
      detail {
        requirementId
        baseId
        self
        createdAt
        title
        description
        notApplicable
        deleted
        updatedAt
        __typename
      }
      assignedToITSpecialist
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const listRequirements = /* GraphQL */ `
  query ListRequirements(
    $filter: ModelRequirementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequirements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        progressId
        requirementId
        ABN
        orgId
        self
        createdAt
        attestationStatus
        attestationNote
        attestedBy
        notApplicableDetails
        detail {
          requirementId
          baseId
          self
          createdAt
          title
          description
          notApplicable
          deleted
          updatedAt
          __typename
        }
        assignedToITSpecialist
        deleted
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const requirementsByDate = /* GraphQL */ `
  query RequirementsByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequirementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    requirementsByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        progressId
        requirementId
        ABN
        orgId
        self
        createdAt
        attestationStatus
        attestationNote
        attestedBy
        notApplicableDetails
        detail {
          requirementId
          baseId
          self
          createdAt
          title
          description
          notApplicable
          deleted
          updatedAt
          __typename
        }
        assignedToITSpecialist
        deleted
        owners
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRequirementDetail = /* GraphQL */ `
  query GetRequirementDetail($requirementId: String!) {
    getRequirementDetail(requirementId: $requirementId) {
      requirementId
      baseId
      self
      createdAt
      title
      description
      notApplicable
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listRequirementDetails = /* GraphQL */ `
  query ListRequirementDetails(
    $requirementId: String
    $filter: ModelRequirementDetailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRequirementDetails(
      requirementId: $requirementId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        requirementId
        baseId
        self
        createdAt
        title
        description
        notApplicable
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const requirementDetailsByDate = /* GraphQL */ `
  query RequirementDetailsByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequirementDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    requirementDetailsByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        requirementId
        baseId
        self
        createdAt
        title
        description
        notApplicable
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDiscount = /* GraphQL */ `
  query GetDiscount($id: ID!) {
    getDiscount(id: $id) {
      id
      customer
      ruleId
      rule {
        id
        type
        rate
        levels
        applyType
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      code
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listDiscounts = /* GraphQL */ `
  query ListDiscounts(
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customer
        ruleId
        rule {
          id
          type
          rate
          levels
          applyType
          self
          createdAt
          deleted
          updatedAt
          __typename
        }
        code
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const discountsByDate = /* GraphQL */ `
  query DiscountsByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discountsByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer
        ruleId
        rule {
          id
          type
          rate
          levels
          applyType
          self
          createdAt
          deleted
          updatedAt
          __typename
        }
        code
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCommission = /* GraphQL */ `
  query GetCommission($id: ID!) {
    getCommission(id: $id) {
      id
      partner
      saleId
      ruleId
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listCommissions = /* GraphQL */ `
  query ListCommissions(
    $filter: ModelCommissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partner
        saleId
        ruleId
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commissionsByDate = /* GraphQL */ `
  query CommissionsByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commissionsByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        saleId
        ruleId
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRule = /* GraphQL */ `
  query GetRule($id: ID!) {
    getRule(id: $id) {
      id
      type
      rate
      levels
      applyType
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listRules = /* GraphQL */ `
  query ListRules(
    $filter: ModelRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        rate
        levels
        applyType
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rulesByDate = /* GraphQL */ `
  query RulesByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rulesByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        rate
        levels
        applyType
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApplyRule = /* GraphQL */ `
  query GetApplyRule($id: ID!) {
    getApplyRule(id: $id) {
      id
      partnerRuleId
      partner
      startDate
      endDate
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listApplyRules = /* GraphQL */ `
  query ListApplyRules(
    $filter: ModelApplyRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplyRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerRuleId
        partner
        startDate
        endDate
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const applyRulesByDate = /* GraphQL */ `
  query ApplyRulesByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelApplyRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applyRulesByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerRuleId
        partner
        startDate
        endDate
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPartnerRule = /* GraphQL */ `
  query GetPartnerRule($id: ID!) {
    getPartnerRule(id: $id) {
      id
      name
      users
      associatedRules
      globalRules
      recruitCommission
      accessRules
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listPartnerRules = /* GraphQL */ `
  query ListPartnerRules(
    $filter: ModelPartnerRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        users
        associatedRules
        globalRules
        recruitCommission
        accessRules
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const partnerRulesByDate = /* GraphQL */ `
  query PartnerRulesByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerRulesByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        users
        associatedRules
        globalRules
        recruitCommission
        accessRules
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccessRule = /* GraphQL */ `
  query GetAccessRule($id: ID!) {
    getAccessRule(id: $id) {
      id
      target
      permissions
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listAccessRules = /* GraphQL */ `
  query ListAccessRules(
    $filter: ModelAccessRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccessRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        target
        permissions
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const accessRulesByDate = /* GraphQL */ `
  query AccessRulesByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccessRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accessRulesByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        target
        permissions
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAuditRequest = /* GraphQL */ `
  query GetAuditRequest($id: ID!) {
    getAuditRequest(id: $id) {
      id
      ABN
      orgId
      certificationId
      level
      attempted
      datePaid
      status
      reviewer
      reviewerABN
      reviewNotes
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listAuditRequests = /* GraphQL */ `
  query ListAuditRequests(
    $filter: ModelAuditRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ABN
        orgId
        certificationId
        level
        attempted
        datePaid
        status
        reviewer
        reviewerABN
        reviewNotes
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const auditRequestsByDate = /* GraphQL */ `
  query AuditRequestsByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    auditRequestsByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ABN
        orgId
        certificationId
        level
        attempted
        datePaid
        status
        reviewer
        reviewerABN
        reviewNotes
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActivityFeed = /* GraphQL */ `
  query GetActivityFeed($id: ID!) {
    getActivityFeed(id: $id) {
      id
      ABN
      orgId
      self
      createdAt
      type
      name
      level
      email
      authoriser
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listActivityFeeds = /* GraphQL */ `
  query ListActivityFeeds(
    $filter: ModelActivityFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityFeeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ABN
        orgId
        self
        createdAt
        type
        name
        level
        email
        authoriser
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activityFeedsByDate = /* GraphQL */ `
  query ActivityFeedsByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityFeedsByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ABN
        orgId
        self
        createdAt
        type
        name
        level
        email
        authoriser
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activitiesByType = /* GraphQL */ `
  query ActivitiesByType(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ABN
        orgId
        self
        createdAt
        type
        name
        level
        email
        authoriser
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      ABN
      orgId
      self
      createdAt
      status
      type
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ABN
        orgId
        self
        createdAt
        status
        type
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsByDate = /* GraphQL */ `
  query NotificationsByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ABN
        orgId
        self
        createdAt
        status
        type
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSale = /* GraphQL */ `
  query GetSale($id: ID!) {
    getSale(id: $id) {
      id
      ABN
      orgId
      email
      item
      certID
      status
      discountRate
      amountPaid
      datePaid
      type
      public
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listSales = /* GraphQL */ `
  query ListSales(
    $filter: ModelSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSales(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ABN
        orgId
        email
        item
        certID
        status
        discountRate
        amountPaid
        datePaid
        type
        public
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const salesByDate = /* GraphQL */ `
  query SalesByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    salesByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ABN
        orgId
        email
        item
        certID
        status
        discountRate
        amountPaid
        datePaid
        type
        public
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      name
      status
      totalReceivers
      success
      fail
      month
      year
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        totalReceivers
        success
        fail
        month
        year
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reportsByDate = /* GraphQL */ `
  query ReportsByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        totalReceivers
        success
        fail
        month
        year
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPartnerReport = /* GraphQL */ `
  query GetPartnerReport($id: ID!) {
    getPartnerReport(id: $id) {
      id
      partner
      reportId
      reportDoc
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listPartnerReports = /* GraphQL */ `
  query ListPartnerReports(
    $filter: ModelPartnerReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partner
        reportId
        reportDoc
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const partnerReportsByDate = /* GraphQL */ `
  query PartnerReportsByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerReportsByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        reportId
        reportDoc
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecommendationTool = /* GraphQL */ `
  query GetRecommendationTool($id: ID!) {
    getRecommendationTool(id: $id) {
      id
      result
      progress {
        id
        value
        __typename
      }
      ABN
      orgId
      eligibleRequirements
      owners
      deleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRecommendationTools = /* GraphQL */ `
  query ListRecommendationTools(
    $filter: ModelRecommendationToolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecommendationTools(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        result
        progress {
          id
          value
          __typename
        }
        ABN
        orgId
        eligibleRequirements
        owners
        deleted
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClaimCode = /* GraphQL */ `
  query GetClaimCode($id: ID!) {
    getClaimCode(id: $id) {
      id
      code
      expired
      ABN
      orgId
      level
      partnerName
      name
      logo
      pageTitle
      description
      dashboardType
      tally
      totalTally
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClaimCodes = /* GraphQL */ `
  query ListClaimCodes(
    $filter: ModelClaimCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaimCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        expired
        ABN
        orgId
        level
        partnerName
        name
        logo
        pageTitle
        description
        dashboardType
        tally
        totalTally
        deleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCheckout = /* GraphQL */ `
  query GetCheckout($id: ID!) {
    getCheckout(id: $id) {
      id
      sessionId
      ABN
      orgId
      item
      status
      source
      userId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCheckouts = /* GraphQL */ `
  query ListCheckouts(
    $filter: ModelCheckoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sessionId
        ABN
        orgId
        item
        status
        source
        userId
        deleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSMSChallenge = /* GraphQL */ `
  query GetSMSChallenge($id: ID!) {
    getSMSChallenge(id: $id) {
      id
      phone
      code
      userId
      codeExpiredAt
      codeResendAllowedAt
      status
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSMSChallenges = /* GraphQL */ `
  query ListSMSChallenges(
    $filter: ModelSMSChallengeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSMSChallenges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phone
        code
        userId
        codeExpiredAt
        codeResendAllowedAt
        status
        deleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPartnerInvite = /* GraphQL */ `
  query GetPartnerInvite($id: ID!) {
    getPartnerInvite(id: $id) {
      id
      partnerABN
      partnerOrgId
      partnerName
      ABN
      orgId
      level
      dashboardType
      status
      specialProgram
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPartnerInvites = /* GraphQL */ `
  query ListPartnerInvites(
    $filter: ModelPartnerInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerABN
        partnerOrgId
        partnerName
        ABN
        orgId
        level
        dashboardType
        status
        specialProgram
        deleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPartnerOffer = /* GraphQL */ `
  query GetPartnerOffer($id: ID!) {
    getPartnerOffer(id: $id) {
      id
      ABN
      orgId
      discount
      dashboard
      expiry
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPartnerOffers = /* GraphQL */ `
  query ListPartnerOffers(
    $filter: ModelPartnerOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ABN
        orgId
        discount
        dashboard
        expiry
        isActive
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPartnerOfferAccept = /* GraphQL */ `
  query GetPartnerOfferAccept($id: ID!) {
    getPartnerOfferAccept(id: $id) {
      id
      offerId
      ABN
      orgId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPartnerOfferAccepts = /* GraphQL */ `
  query ListPartnerOfferAccepts(
    $filter: ModelPartnerOfferAcceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerOfferAccepts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        offerId
        ABN
        orgId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      id
      type
      token
      ABN
      orgId
      questionnaire {
        id
        requirement
        question
        __typename
      }
      maximumLevel
      dashboard
      isActive
      isSetup
      activeAt
      submitedAt
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssessments = /* GraphQL */ `
  query ListAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        token
        ABN
        orgId
        questionnaire {
          id
          requirement
          question
          __typename
        }
        maximumLevel
        dashboard
        isActive
        isSetup
        activeAt
        submitedAt
        deleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssessmentResult = /* GraphQL */ `
  query GetAssessmentResult($id: ID!) {
    getAssessmentResult(id: $id) {
      id
      type
      assessmentId
      assessmentToken
      ABN
      orgId
      name
      result
      answers {
        id
        requirement
        value
        __typename
      }
      implementedRequirements
      completedBy
      session
      verifiedVia
      verifiedData
      verifiedAt
      completedAt
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssessmentResults = /* GraphQL */ `
  query ListAssessmentResults(
    $filter: ModelAssessmentResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        assessmentId
        assessmentToken
        ABN
        orgId
        name
        result
        answers {
          id
          requirement
          value
          __typename
        }
        implementedRequirements
        completedBy
        session
        verifiedVia
        verifiedData
        verifiedAt
        completedAt
        deleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSpecialOffer = /* GraphQL */ `
  query GetSpecialOffer($id: ID!) {
    getSpecialOffer(id: $id) {
      id
      name
      isActive
      expiryAccActive
      expiryDays
      goldGrant
      silverGrant
      bronzeGrant
      banner
      logo
      hero
      grantedLevel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSpecialOffers = /* GraphQL */ `
  query ListSpecialOffers(
    $filter: ModelSpecialOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        isActive
        expiryAccActive
        expiryDays
        goldGrant
        silverGrant
        bronzeGrant
        banner
        logo
        hero
        grantedLevel
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSpecialOfferAccept = /* GraphQL */ `
  query GetSpecialOfferAccept($id: ID!) {
    getSpecialOfferAccept(id: $id) {
      id
      offerId
      offerName
      ABN
      orgId
      status
      dashboard
      firstEmailActive
      firstEmailReminder
      firstEmailTemplate
      secondEmailActive
      secondEmailReminder
      secondEmailTemplate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSpecialOfferAccepts = /* GraphQL */ `
  query ListSpecialOfferAccepts(
    $filter: ModelSpecialOfferAcceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialOfferAccepts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        offerId
        offerName
        ABN
        orgId
        status
        dashboard
        firstEmailActive
        firstEmailReminder
        firstEmailTemplate
        secondEmailActive
        secondEmailReminder
        secondEmailTemplate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCAPReferral = /* GraphQL */ `
  query GetCAPReferral($code: String!) {
    getCAPReferral(code: $code) {
      code
      self
      partnerABN
      partnerOrgId
      email
      linkToReferrer
      firstName
      lastName
      isActive
      certBundle
      dashboard
      referralPartners
      specialOffer
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listCAPReferrals = /* GraphQL */ `
  query ListCAPReferrals(
    $code: String
    $filter: ModelCAPReferralFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCAPReferrals(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        self
        partnerABN
        partnerOrgId
        email
        linkToReferrer
        firstName
        lastName
        isActive
        certBundle
        dashboard
        referralPartners
        specialOffer
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CAPReferralsByDate = /* GraphQL */ `
  query CAPReferralsByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCAPReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CAPReferralsByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        code
        self
        partnerABN
        partnerOrgId
        email
        linkToReferrer
        firstName
        lastName
        isActive
        certBundle
        dashboard
        referralPartners
        specialOffer
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCertBundle = /* GraphQL */ `
  query GetCertBundle($id: ID!) {
    getCertBundle(id: $id) {
      id
      self
      bundleName
      bronzeQTY
      silverQTY
      goldQTY
      platinumQTY
      diamondQTY
      isActive
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listCertBundles = /* GraphQL */ `
  query ListCertBundles(
    $id: ID
    $filter: ModelCertBundleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCertBundles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        self
        bundleName
        bronzeQTY
        silverQTY
        goldQTY
        platinumQTY
        diamondQTY
        isActive
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const certBundlesByDate = /* GraphQL */ `
  query CertBundlesByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCertBundleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    certBundlesByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        self
        bundleName
        bronzeQTY
        silverQTY
        goldQTY
        platinumQTY
        diamondQTY
        isActive
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPendingPartner = /* GraphQL */ `
  query GetPendingPartner($ABN: String!) {
    getPendingPartner(ABN: $ABN) {
      ABN
      orgId
      orgCountry
      location
      entityType
      self
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      initialDashboardType
      name
      addedAs
      industry
      type
      ACN
      ANZIC
      orgPhone
      website
      numEmployees
      HOaddressCountry
      HOaddressStreet
      HOaddressSuburb
      HOaddressState
      HOaddressPostcode
      POaddressCountry
      POaddressStreet
      POaddressSuburb
      POaddressState
      POaddressPostcode
      firstName
      lastName
      email
      workPhone
      phone
      role
      RMFirstName
      RMLastName
      RMEmail
      RMPhone
      BDMFirstName
      BDMLastName
      BDMEmail
      BDMPhone
      RANaddressFirstName
      RANaddressLastName
      RANaddressEmail
      RANaddressPhone
      RANaddressRole
      RANaddressStreet
      RANaddressSuburb
      RANaddressState
      RANaddressPostcode
      RANaddressCountry
      commencementDate
      annualPartnerFee
      subscriptionDiscount
      codeExpiry
      minimumVolume
      volumeDiscount
      discount
      referredBy
      certBundle
      isSubmissionDone
      isPartnerSigned
      partnerSignedAt
      isAdminSigned
      adminSignedAt
      signingSession
      envelopeId
      envelopeSentAt
      isOnboarded
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listPendingPartners = /* GraphQL */ `
  query ListPendingPartners(
    $ABN: String
    $filter: ModelPendingPartnerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPendingPartners(
      ABN: $ABN
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ABN
        orgId
        orgCountry
        location
        entityType
        self
        isTypeCAP
        isTypeReferrer
        isTypeMSP
        isTypeConsultant
        isTypeSupplyChain
        isTypeMembership
        isTypeFranchise
        isTypeVendor
        initialDashboardType
        name
        addedAs
        industry
        type
        ACN
        ANZIC
        orgPhone
        website
        numEmployees
        HOaddressCountry
        HOaddressStreet
        HOaddressSuburb
        HOaddressState
        HOaddressPostcode
        POaddressCountry
        POaddressStreet
        POaddressSuburb
        POaddressState
        POaddressPostcode
        firstName
        lastName
        email
        workPhone
        phone
        role
        RMFirstName
        RMLastName
        RMEmail
        RMPhone
        BDMFirstName
        BDMLastName
        BDMEmail
        BDMPhone
        RANaddressFirstName
        RANaddressLastName
        RANaddressEmail
        RANaddressPhone
        RANaddressRole
        RANaddressStreet
        RANaddressSuburb
        RANaddressState
        RANaddressPostcode
        RANaddressCountry
        commencementDate
        annualPartnerFee
        subscriptionDiscount
        codeExpiry
        minimumVolume
        volumeDiscount
        discount
        referredBy
        certBundle
        isSubmissionDone
        isPartnerSigned
        partnerSignedAt
        isAdminSigned
        adminSignedAt
        signingSession
        envelopeId
        envelopeSentAt
        isOnboarded
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const pendingPartnersByDate = /* GraphQL */ `
  query PendingPartnersByDate(
    $self: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPendingPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pendingPartnersByDate(
      self: $self
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ABN
        orgId
        orgCountry
        location
        entityType
        self
        isTypeCAP
        isTypeReferrer
        isTypeMSP
        isTypeConsultant
        isTypeSupplyChain
        isTypeMembership
        isTypeFranchise
        isTypeVendor
        initialDashboardType
        name
        addedAs
        industry
        type
        ACN
        ANZIC
        orgPhone
        website
        numEmployees
        HOaddressCountry
        HOaddressStreet
        HOaddressSuburb
        HOaddressState
        HOaddressPostcode
        POaddressCountry
        POaddressStreet
        POaddressSuburb
        POaddressState
        POaddressPostcode
        firstName
        lastName
        email
        workPhone
        phone
        role
        RMFirstName
        RMLastName
        RMEmail
        RMPhone
        BDMFirstName
        BDMLastName
        BDMEmail
        BDMPhone
        RANaddressFirstName
        RANaddressLastName
        RANaddressEmail
        RANaddressPhone
        RANaddressRole
        RANaddressStreet
        RANaddressSuburb
        RANaddressState
        RANaddressPostcode
        RANaddressCountry
        commencementDate
        annualPartnerFee
        subscriptionDiscount
        codeExpiry
        minimumVolume
        volumeDiscount
        discount
        referredBy
        certBundle
        isSubmissionDone
        isPartnerSigned
        partnerSignedAt
        isAdminSigned
        adminSignedAt
        signingSession
        envelopeId
        envelopeSentAt
        isOnboarded
        createdAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserInvite = /* GraphQL */ `
  query GetUserInvite($id: ID!) {
    getUserInvite(id: $id) {
      id
      userId
      email
      phone
      fullName
      firstName
      lastName
      role
      status
      ABN
      orgId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserInvites = /* GraphQL */ `
  query ListUserInvites(
    $filter: ModelUserInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        email
        phone
        fullName
        firstName
        lastName
        role
        status
        ABN
        orgId
        deleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRegion = /* GraphQL */ `
  query GetRegion($countryCode: String!) {
    getRegion(countryCode: $countryCode) {
      countryCode
      countryName
      description
      supportedLanguages
      localePaths
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRegions = /* GraphQL */ `
  query ListRegions(
    $countryCode: String
    $filter: ModelRegionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRegions(
      countryCode: $countryCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        countryCode
        countryName
        description
        supportedLanguages
        localePaths
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGovID = /* GraphQL */ `
  query GetGovID($countryCode: String!) {
    getGovID(countryCode: $countryCode) {
      countryCode
      desctipion
      format
      idName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGovIDS = /* GraphQL */ `
  query ListGovIDS(
    $countryCode: String
    $filter: ModelGovIDFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGovIDS(
      countryCode: $countryCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        countryCode
        desctipion
        format
        idName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPendingExternalSpecialist = /* GraphQL */ `
  query GetPendingExternalSpecialist($id: ID!) {
    getPendingExternalSpecialist(id: $id) {
      id
      ABN
      name
      phone
      website
      addedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPendingExternalSpecialists = /* GraphQL */ `
  query ListPendingExternalSpecialists(
    $filter: ModelPendingExternalSpecialistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPendingExternalSpecialists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ABN
        name
        phone
        website
        addedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCertificationSpecialistEngagement = /* GraphQL */ `
  query GetCertificationSpecialistEngagement($id: ID!) {
    getCertificationSpecialistEngagement(id: $id) {
      id
      ABN
      certificationId
      specialistABN
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCertificationSpecialistEngagements = /* GraphQL */ `
  query ListCertificationSpecialistEngagements(
    $filter: ModelCertificationSpecialistEngagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertificationSpecialistEngagements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ABN
        certificationId
        specialistABN
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEOIRegistration = /* GraphQL */ `
  query GetEOIRegistration($id: ID!) {
    getEOIRegistration(id: $id) {
      id
      countryCode
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEOIRegistrations = /* GraphQL */ `
  query ListEOIRegistrations(
    $id: ID
    $filter: ModelEOIRegistrationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEOIRegistrations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        countryCode
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
